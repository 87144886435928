import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Header from '../components/Header';
import { Main, LinkBtnGold, Container } from '../components/Elements';
import fiText from '../locales/fi.js';

const NotFoundPage = () => (
  <Fragment>
    <Header />
    <Main
      textAlign="center"
      bg={'beigeBg'}
      color={'blueDark'}
      height={'70vh'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <h4>{fiText.notification404}</h4>
      <Container justifyContent="center">
        <LinkBtnGold mx="auto" to="/">
          {fiText.goToHomePage}
        </LinkBtnGold>
      </Container>
    </Main>
  </Fragment>
);

export default NotFoundPage;
